
import { defineComponent, reactive , ref, watch,watchEffect } from 'vue'
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
   
    setup() {
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();
        const ruleFormRef = ref<null | HTMLFormElement>(null);
        const router = useRouter()
        const store = useStore();
        const selectedDay = reactive({
            max : 0
        });

        const form = reactive({
            mes: '',
            ano: anoAtual,
            dia: ''
        })

        watch(() => store.getters.concessionariaSelectedInfo , () => {
           form.ano = anoAtual
           form.mes = ''
           form.dia = ''           
        })

        watchEffect(() => {
            const lastDayOfMonth = new Date(form.ano, parseInt(form.mes), 0).getDate();
            selectedDay.max = 0;
            selectedDay.max = lastDayOfMonth;
        });

        // Essa função é chamada sempre que o dia selecionado mudar
        watchEffect(() => {
        if (selectedDay.max > selectedDay.max) {
            selectedDay.max = selectedDay.max;
        }
        });
        
        const  validaDia = (rule: any,dia: any,callback: any) => {

            if(form.mes == ''){
                callback(new Error('Selecione o mês'))

                return false
            }

            if(dia == '') {
                callback(new Error('Dia Obrigatorio'))

                return false
            }

            if (Number(form.dia) <= selectedDay.max) {
                
                selectedDay.max = selectedDay.max;

                return true
            }else {
                callback(new Error('O dia inserido é maior do que o número de dias que este mês possui. Por favor, insira um dia válido.'))
                return false
            }
        }
        
        const rules = ref({
            mes: [
                {required: true, message:'selecione o mês' ,  trigger: "blur" }
            ],
            ano: [
                {required: true, message:'selecione o ano', trigger: "blur" }
            ],
            dia: [
                {required: true, trigger: "blur", validator: validaDia}
            ]
        })

        const submit = () => {
            if (!ruleFormRef.value) return;
            ruleFormRef.value.validate((valid) => {
                    if (valid){                        
                        router.push({name: 'novo-lancamento-mensal' , params: form})
                    }
            })
        }

       function somenteNumeros(event) {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
       }

        return {
            form,
            ruleFormRef,
            rules,
            submit,
            somenteNumeros
        }
    }
})
