
  import { defineComponent, reactive, toRefs, ref, onMounted, watch,  } from "vue";
  import { ContentLoader } from 'vue-content-loader';
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import useAlert from "@/composables/Alert";
  import { hideModal } from '@/core/helpers/dom';
  import { deleteLancamentos } from "@/services/LancamentoMensal";
  import useEmitter from "@/composables/Emmiter";
  import { converterDataNumber } from '../composables/dataNumberConverter'

  export default {
    name: "deletGrupoModal",
    props: {
       dadosLancamento: {       
        required: true,
      }, 
    },
    components:{
        ContentLoader
    },
    setup(props, {emit}) {
      const logs = ref();
      const codConcessionaria = ref();
      const animate = ref(true);
      const emitter = useEmitter()
      const {  showTimeAlert } = useAlert();


      watch(() => props.dadosLancamento, () => {   
        logs.value = props.dadosLancamento
      },{deep: true});

      function postDeleteLancamentos(){        
        try{
           deleteLancamentos(logs.value.codConcessionaria , logs.value.codLancamento)
        }catch(err){
          showTimeAlert("Não foi possível completar a solicitação", "error");
        }
      }

      function buttonDelete(){
          animate.value = true
          if (props.dadosLancamento) {
            Swal.fire({
              title: "Confirmação de exclusão ",
              text: "Essa alteração não poderá ser desfeita!",
              showCancelButton: true,
              confirmButtonText: "Excluir Lançamento",
              cancelButtonText: "Cancelar",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-flex background_btn_1_opct text-white",
                cancelButton: "btn btn-light me-3",
              },
              reverseButtons: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                    await postDeleteLancamentos()
                    emitter.emit('update-release-list')
                    const elementModal = document.getElementById("kt_modallog_1");
                    hideModal(elementModal);    
                    showTimeAlert("Lançamento excluído com sucesso");
                } 
            });
              animate.value = false
          }
      }
    
   
      return {
        logs,
        animate,
        codConcessionaria,  
        buttonDelete,
        converterDataNumber
      };
    },
  };
  